ion-tab-bar,
ion-tab-button {
  background-color: #100e17;
}

ion-label {
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.selected-tab {
  color: #fc8016;
}
