// General table style over the bootstrap table classes

.table,
.table-bordered,
.prime-table {
  width: 100%;
  th,
  td {
    text-align: center;
    prime-field-update-button {
      margin-left: 5px;
    }
  }

  th {
    font-size: 14px;
    color: white;
    font-weight: 500;
    background: #666;
    button[mattooltip],
    button[mat-button] {
      background-color: #666;
      border-style: none;
    }

    p {
      margin: 0px;
    }
  }

  /* 
    Table header widths
  */
  th.w-20 {
    width: 20%;
  }

  th.w-25 {
    width: 25%;
  }

  th.w-33 {
    width: 33%;
  }

  th.w-50 {
    width: 50%;
  }
}

i.danger-icon {
  color: red;
}
