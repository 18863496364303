@import './angular-material-overrides.scss';
@import './prime-global-styles.scss';
@import '~@ionic/angular/css/core.css';
@import './ionic/prime-ionic.scss';

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0px;
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

.fill-container,
.window-content {
  height: 100%;
  width: 100%;
}

.fill-height {
  height: 100%;
}

.bold {
  font-weight: bold;
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

.loading-container {
  margin-top: 5%;
  h4 {
    text-align: center;
  }

  .spinner {
    margin: 25px auto;
  }
}
.field-with-buttons mat-form-field {
  width: 70%;
}

mat-form-field {
  width: 100%;
}

.vdc-table {
  .header {
    color: white;
    background-color: #666;
  }

  .header,
  .content {
    border: 1px solid lightgray;
    height: 55px;
    text-align: center;
    float: left;
  }

  .header {
    padding-top: 10px;
  }

  .content {
    padding-top: 15px;
  }
}

.search-list-dialog {
  --height: 75%;
  --width: 75%;
  overflow-y: scroll;
}

// Custom XXL bootstrap grid class
@media (min-width: 1600px) {
  .col-xxl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xxl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xxl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xxl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xxl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xxl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xxl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xxl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xxl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xxl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xxl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.badges {
  .proj-badge {
    border-radius: 5px;
    margin: 4px;
    padding: 4px 6px;
    color: white;
  }
  .proj-complete {
    margin-right: 5px;
    background-color: #10dc60;
  }

  .proj-not-complete {
    background-color: #989aa2;
  }
}

.dark-gradient-back {
  background-image: linear-gradient(rgba(33, 33, 33, 0.8), rgba(33, 33, 33, 0.6));
}

.seq-template-dialog {
  --height: 75%;
  --width: 75%;
  overflow-y: scroll;
}
