@import './material/material.scss';

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  color: white;
}

.mat-card:not([class*='mat-elevation-z']) {
  &.bordered {
    box-shadow: 0 -1px 1px -1px var(--theme-accent), 0 1px 1px 0px var(--theme-accent),
      0 1px 3px 0 var(--theme-accent);
  }
}
