table[mat-table] {
  width: 100%;

  & thead tr.mat-header-row th[mat-header-cell] {
    color: white;
  }

  th,
  td {
    text-align: center;
    border-left: 1px solid lightgray;
    &:last-child {
      border-right: 1px solid lightgray;
    }
  }

  th {
    font-size: 14px;
    font-weight: 500;
    background: var(--theme-secondary);
  }
}
