/* You can add global styles to this file, and also import other style files */
.mat-card:not([class*="mat-elevation-z"]) {
  &.bs-orange {
    box-shadow: 0 -1px 1px -1px rgb(252, 128, 22),
      0 1px 1px 0px rgb(252, 128, 22), 0 1px 3px 0 rgb(252, 128, 22);
  }
  &.bs-black {
    box-shadow: 0 -1px 1px -1px rgb(33, 33, 33), 0 1px 1px 0px rgb(33, 33, 33),
      0 1px 3px 0 rgb(33, 33, 33);
  }
}

mat-form-field {
  .mat-form-field-wrapper {
    padding: 0px;
  }
}

button.mat-raised-button {
  margin: 3px;
}

.mat-radio-button {
  margin-left: 8px;
}

// Some inner windows create scollbars of their own. Conditonally add class to outer frames as needed
.no-scroll {
  overflow-y: hidden;
  -webkit-scrollbar {
    width: 0 !important;
  }
}

.bim-nav-container {
  margin-top: 20px;
  padding-bottom: 5%;
}

.fa-trash-alt {
  color: red;
  margin: 2px;
}

.date-wrapper {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px;
  background-color: rgba(102, 102, 102, 0.5);
  color: white;
  mat-form-field {
    background-color: white;
    color: black;
    &.mat-form-field-appearance-outline .mat-form-field-wrapper {
      margin: 0px !important;
    }

    i {
      color: black;
    }
  }

  button {
    margin-top: 3px;
  }
}

.open-panel {
  background-color: lightgray !important;
}

.loader {
  mat-spinner {
    margin: 100px auto;
  }
}

.vdc-icon {
  color: #fc8016;
  &:hover {
    cursor: pointer;
  }
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #fc8016 !important;
}

.three-buttons-alert button.alert-button-group {
  width: 33.33%;
}

.three-buttons-alert .alert-button-group .button-inner {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.three-buttons-alert .alert-button-group-vertical {
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
