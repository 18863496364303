.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--theme-accent);
  height: 4px;
}

.mat-tab-label-container,
.mat-tab-links {
  background-color: var(--theme-primary);
  opacity: 0.95;
  & .mat-tab-label,
  .mat-tab-link {
    color: white;
    opacity: 1;
  }
}

.mat-tab-group {
  height: 100%;
  & .mat-tab-body-wrapper {
    flex: 1 1 100%;
  }
}
